<template lang="pug">
div
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    chat(
      v-if="showChat"
      style="height: 70vh"
      :problem="problem"
      :description="description"
      @assistanceEnd="assistanceEnd"
      @followUpUpdate="followUpUpdate"
    )

    b-row(v-else)
      b-col(cols="12")
      div
        form-wizard.wizard-vertical.mb-3(
          :color="colors.primary"
          :title="null",
          :subtitle="null",
          layout="horizontal",
          :finish-button-text="$t('message.submit')",
          :back-button-text="$t('message.previous')",
          :next-button-text="$t('message.next')",
          @on-complete="formSubmitted",
          ref="assistanceForm"
        )
          // Problem Context
          tab-content(:title="$t('message.problem_context')", :before-change="() => validateTab(problemContextTab)")
            validation-observer(ref="problemContextTab", tag="form")
              b-row
                b-col(cols="12")
                  b-form-group(:label="$t('message.problem_context')")
                    validation-provider(:name="$t('message.problem_context')", rules="required", v-slot="{ errors }")
                      b-form-textarea(v-model="assistance.problem")
                      small(class="text-danger") {{ errors[0] }}
          
          // Actual State
          tab-content(:title="$t('message.actual_state')", :before-change="() => validateTab(actualStateTab)")
            validation-observer(ref="actualStateTab", tag="form")
              b-row
                b-col(cols="12")
                  b-form-group(:label="$t('message.actual_state')")
                    validation-provider(:name="$t('message.actual_state')", rules="required", v-slot="{ errors }")
                      b-form-textarea(v-model="assistance.actualState")
                      small(class="text-danger") {{ errors[0] }}

          // Root Cause Analysis
          tab-content(:title="$t('message.root_cause_analysis')", :before-change="() => validateTab(rootCauseAnalysisTab)")
            validation-observer(ref="rootCauseAnalysisTab", tag="form")
              b-row
                b-col(cols="12")
                  b-form-group(:label="assistance.whyQuestion1 ? `1- ${assistance.whyQuestion1}` : $t('message.why_1')" style="font-weight: 700;")
                    validation-provider(:name="$t('message.why_1')", rules="required", v-slot="{ errors }")
                      b-form-input(v-model="assistance.whyAnswer1")
                      small(class="text-danger") {{ errors[0] }}
                b-col(cols="12")
                  b-form-group(:label="assistance.whyQuestion2 ? `2- ${assistance.whyQuestion2}` : $t('message.why_2')" style="font-weight: 700;")
                    validation-provider(:name="$t('message.why_2')", rules="required", v-slot="{ errors }")
                      b-form-input(v-model="assistance.whyAnswer2")
                      small(class="text-danger") {{ errors[0] }}
                b-col(cols="12")
                  b-form-group(:label="assistance.whyQuestion3 ? `3- ${assistance.whyQuestion3}` : $t('message.why_3')" style="font-weight: 700;")
                    validation-provider(:name="$t('message.why_3')", rules="required", v-slot="{ errors }")
                      b-form-input(v-model="assistance.whyAnswer3")
                      small(class="text-danger") {{ errors[0] }}
                b-col(cols="12")
                  b-form-group(:label="assistance.whyQuestion4 ? `4- ${assistance.whyQuestion4}` : $t('message.why_4')" style="font-weight: 700;")
                    validation-provider(:name="$t('message.why_4')", rules="required", v-slot="{ errors }")
                      b-form-input(v-model="assistance.whyAnswer4")
                      small(class="text-danger") {{ errors[0] }}
                b-col(cols="12")
                  b-form-group(:label="assistance.whyQuestion5 ? `5- ${assistance.whyQuestion5}` : $t('message.why_5')" style="font-weight: 700;")
                    validation-provider(:name="$t('message.why_5')", rules="required", v-slot="{ errors }")
                      b-form-input(v-model="assistance.whyAnswer5")
                      small(class="text-danger") {{ errors[0] }}
                b-col(cols="12")
                  b-form-group(:label="$t('message.root_cause')" style="font-weight: 700;")
                    validation-provider(:name="$t('message.root_cause')", rules="required", v-slot="{ errors }")
                      b-form-textarea(v-model="assistance.rootCause")
                      small(class="text-danger") {{ errors[0] }}

          // Future State
          tab-content(:title="$t('message.future_state')", :before-change="() => validateTab(futureStateTab)")
            validation-observer(ref="futureStateTab", tag="form")
              b-row
                b-col(cols="12")
                  b-form-group(:label="$t('message.future_state')")
                    validation-provider(:name="$t('message.future_state')", rules="required", v-slot="{ errors }")
                      b-form-textarea(v-model="assistance.futureState")
                      small(class="text-danger") {{ errors[0] }}
          
          // Action Plan
          tab-content(:title="$t('message.action_plan')", :before-change="() => validateTab(actionPlanTab)")
            validation-observer(ref="actionPlanTab", tag="form")
              b-row
                b-col(cols="12")
                  b-form-group(:label="$t('message.action_plan')")
                    validation-provider(:name="$t('message.future_state')", rules="required", v-slot="{ errors }")
                      b-form-textarea(v-model="assistance.actionPlan" style="height: 12rem")
                      small(class="text-danger") {{ errors[0] }}
                b-col(cols="12")
                  b-form-group(:label="commitmentFunctionality ? $t('label.commitments') : $t('label.improvements')")
                    ul.list-group(:class="`mb-${improvements.length && '1'}`")
                      li.list-group-item.d-flex.justify-content-between.align-items-center.pt-0.pb-0(v-for="(improvement, index) in improvements" :key="index")
                        span
                          | {{ improvement.note }}
                        div
                          edit-button(@clicked="handleImprovementEdit(improvement, index)")
                          delete-button(v-b-modal="'improvement list' + index")
                          b-modal(
                            :id="'improvement list' + index" 
                            centered='' 
                            no-close-on-backdrop='' 
                            modal-class='modal-danger' 
                            ok-variant='danger' 
                            cancel-variant='outline-secondary' 
                            :title="$t('message.confirm_action')" 
                            :ok-title="$t('message.delete')" 
                            :cancel-title="$t('message.cancel')" 
                            @ok="removeImprovement(index)"
                          )
                            | {{ commitmentFunctionality ? $t('message.confirm_delete_commitment') : $t('message.confirm_delete_improvement') }}
                    b-button(
                      type="button",
                      variant="primary"
                      @click="isImprovementHandlerSidebarActive = true"
                    )
                      | {{ $t('message.add') }}

          // Follow Up
          tab-content(:title="$t('message.follow_up')", :before-change="() => validateTab(followUpTab)")
            validation-observer(ref="followUpTab", tag="form") 
              b-row
                // Start
                b-col.mb-1.mb-md-0(md="3")
                  b-form-group(:label="$t('label.start_date')")
                    validation-provider(:name="$t('label.start_date')", rules="required", v-slot="{ errors }")
                      flat-pickr(v-model="followUp.startDate", class="form-control", :config="datePickerConfig")
                      small(class="text-danger") {{ errors[0] }}
                // End
                b-col.mb-1.mb-md-0(md="3")
                  b-form-group(:label="$t('label.end_date')")
                    validation-provider(:name="$t('label.end_date')", rules="required", v-slot="{ errors }")
                      flat-pickr(v-model="followUp.endDate", class="form-control", :config="datePickerConfig")
                      small(class="text-danger") {{ errors[0] }}
                // Participants
                b-col(md="12")
                  b-form-group(:label="$t('label.participants')")
                    validation-provider(:name="$t('label.participants')", rules="required", v-slot="{ errors }")
                      v-select#v-improvement-assignee(
                        v-model="followUp.participants",
                        label="title",
                        :multiple="true",
                        :options="workersSupervised",
                        :reduce="(worker) => worker.value"
                        :placeholder="$t('placeholder.participants')"
                      )
                      small(class="text-danger") {{ errors[0] }}

  todo-task-handler-sidebar(
    v-model="isImprovementHandlerSidebarActive",
    :task="improvement",
    :taskIndex="improvementIndex",
    :clear-task-data="clearImprovementData",
    :domains="domains",
    :optionsMetadata="optionsMetadata",
    handlerId="improvement",
    @add-task="addImprovement",
    @update-task="modifyImprovement"
  )
</template>

<script>
import vSelect from "vue-select"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { FormWizard, TabContent } from "vue-form-wizard"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, BFormDatepicker, BFormTimepicker, BFormSpinbutton, BInputGroup, BInputGroupPrepend, BInputGroupAppend } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations"
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component"
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useAssistanceNew from './useAssistanceNew'
import Chat from './Chat.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormTimepicker,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    ToastificationContent,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
    TodoTaskHandlerSidebar,
    EditButton,
    DeleteButton,
    Chat,
    Loading,
  },
  directives: {
    Ripple,
  },
  setup() {

    const {
      commitmentFunctionality,
      datePickerConfig,
      problemContextTab,
      actualStateTab,
      rootCauseAnalysisTab,
      futureStateTab,
      actionPlanTab,
      followUpTab,
      // assistance,
      followUp,
      formSubmitted,
      validateTab,
      workersSupervised,
      isLoading,
      description,
      problem,
      showChat,
      assistance,
      assistanceEnd,
      followUpUpdate,

      // Add improvements
      improvement,
      improvements,
      handleImprovementEdit,
      removeImprovement,
      isImprovementHandlerSidebarActive,
      improvementIndex,
      clearImprovementData,
      domains,
      optionsMetadata,
      addImprovement,
      modifyImprovement,
    } = useAssistanceNew()

    return {
      formSubmitted,
      workersSupervised,
      problemContextTab,
      actualStateTab,
      rootCauseAnalysisTab,
      futureStateTab,
      actionPlanTab,
      followUpTab,
      validateTab,
      required,
      // assistance,
      followUp,
      datePickerConfig,
      commitmentFunctionality,
      isLoading,
      description,
      problem,
      showChat,
      assistance,
      assistanceEnd,
      followUpUpdate,

      // Add improvements
      improvement,
      improvements,
      handleImprovementEdit,
      removeImprovement,
      isImprovementHandlerSidebarActive,
      improvementIndex,
      clearImprovementData,
      domains,
      optionsMetadata,
      addImprovement,
      modifyImprovement,

      colors
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
