<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit" class="chat-application">

    <!-- Stages -->
    <div class="titleContain">
      <label v-for="(data, index) in stageData" :key="index" :class="getClass(index)">{{ data }}
        <label v-if="(index !== stageData.length -1)" :class="getClass(index)" >&#8594; &nbsp;</label>
      </label>
    </div>

    <!-- Chat Main Area -->
    <section class="chat-app-window">

      <!-- Chat Content -->
      <div style="width: 100%" class="active-chat">

        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header" :style="{ 'background-color': skinValue === 'dark' ? '#283046' : '' }">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- <h6 class="mb-0">IA</h6> -->
              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                badge-variant="success"
              />
              <div class="d-flex flex-column">
                <h6 class="mb-0">{{ $t('ai.adviserHabit') }}</h6>
                <small class="mb-0">{{ $t('ai.chatbot') }}</small>             
              </div>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center" style="opacity: 0.5">
              <feather-icon icon="PhoneCallIcon" size="17" class="cursor-pointer d-sm-block d-none mr-1" />
              <feather-icon icon="VideoIcon" size="17" class="cursor-pointer d-sm-block d-none mr-1" />
              <feather-icon icon="SearchIcon" size="17" class="cursor-pointer d-sm-block d-none mr-50" />
              <feather-icon icon="MoreVerticalIcon" size="17" class="cursor-pointer align-middle text-body" />
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
        <chat-log
          :chat-data="activeChat"
          :disabledButton="disabledButton"
          :activeStage="activeStage"
          :skinValue="skinValue"
          @chatFlow="chatFlow"
          @followUpData="followUpData"
        />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendUserMessage" :style="{ 'background-color': skinValue === 'dark' ? '#283046' : '' }">
          <b-input-group class="input-group-merge-custom form-send-message mr-1">
            <b-form-input :disabled="disabled" v-model="inputMessage" ref="inputRef" :placeholder="$t('placeholder.message')"/>
          </b-input-group>
          <b-button variant="blackbtn btn-dark" type="submit" :disabled="disabled">
            {{ $t('ai.send') }}
          </b-button>
          <b-button variant="light" type="text" style="border-color: #CCCCCC; margin-left: 1rem;" @click="clearChatData">
            {{ $t('ai.restart') }}
          </b-button>
        </b-form>
        <div class="ml-2 mb-2">
          <small class="py-1" style="color: #6c757d;">{{ $t('ai.inconsistencyMessage') }}</small>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, nextTick, computed, watch } from '@vue/composition-api'
import { BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from './ChatLog.vue'
import flowiseConnection from "@/views/habit/flowise.js"
import i18n from '@/libs/i18n'
import useCommon from "@/views/organization/useCommon"
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,
    ChatLog
  },
  props: {
    problem: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {

    const blankChat = {
      contact: {
        id: 1,
        avatar: require('@/assets/images/avatars/chatbot.png'),
      },
      chat: {
        chat: [ 
          {
            senderId: 1,
            message: i18n.t('ai.firstMessage'),
            button: {
              type: 'start',
              i18nVariable: 'ai.startAssistance'
            },
          },
        ],
      },
    }
    const stageData = computed(() => {
      return [
        i18n.t('message.problem_context'),
        i18n.t('message.actual_state'),
        i18n.t('message.root_cause_analysis'),
        i18n.t('message.future_state'),
        i18n.t('message.action_plan'),
        i18n.t('message.follow_up'),
      ]
    })
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const { flowise } = flowiseConnection()
    const { generateObjectId } = useCommon()
    const language = default_language === 'es' ? 'español' : 'english'
    const inputMessage = ref('')
    const activeMessage = ref('')
    const responseFlowise = ref('')
    const activeChat =  ref(JSON.parse(JSON.stringify(blankChat)))
    const assistance = ref({})
    const disabledButton = ref(false)
    const disabled = ref(true)
    const activeStage = ref(-1)
    const sessionId = generateObjectId()
    const { skin } = useAppConfig()
    const skinValue = computed(() => skin.value)
    const inputRef = ref(null)
    const initialPrompt = `
      Start the flow with agent-flow-tool. The conversation must be in this language: ${language}
      problem: ${props.problem}
      description: ${props.description}
    `
    const clearChatData = () => {
      activeMessage.value = ''
      responseFlowise.value = ''
      activeChat.value =  JSON.parse(JSON.stringify(blankChat))
      assistance.value = {}
      disabledButton.value = false
      disabled.value = true
      activeStage.value = -1
    }

    // Control of the entire chat flow
    const chatFlow = async (value) => {
      if(value !== 'last') {
        const prompt = value === 'start' ? initialPrompt : i18n.t('ai.msgNextStage')
        typingMessage()
        disabledButton.value = true
        if(value === 'start') activeStage.value = 0
        await fetchFlowise(prompt, sessionId)
        sendChatMessage()
      } else {
        emit('assistanceEnd', assistance.value)
      }
    }

    // Updating tracking information selected from chat by user
    const followUpData = async (data) => {
      emit('followUpUpdate', data)
      activeStage.value++
      responseFlowise.value = '{}'
      typingMessage()
      sendChatMessage()
    }

    // Control of messages sent by the chat assistance
    const sendChatMessage = async () => {
      if(!responseFlowise.value) return

      activeChat.value.chat.chat.pop()
      // Check if the text contains '{' or '}'
      const startBraceIndex = responseFlowise.value.indexOf('{')
      const endBraceIndex = responseFlowise.value.lastIndexOf('}')

      if(startBraceIndex !== -1 && endBraceIndex !== -1 && startBraceIndex < endBraceIndex) {
        // Remove everything before '{' and after '}'
        responseFlowise.value = responseFlowise.value.slice(startBraceIndex, endBraceIndex + 1) // +1 --> to include the last '}'
        try {
          const obj = JSON.parse(responseFlowise.value)
          if(obj) assistance.value = {...assistance.value, ...obj}

          const message = activeStage.value === 6
            ? i18n.t('ai.lastMessage')
              : `${i18n.t('ai.stageCompleted')} ${activeStage.value+1}: ${stageData.value[activeStage.value]}`
            
          const objectToSend = {
            senderId: 1,
            message,
            button: {
              type: activeStage.value === 6 ? 'last' : 'next',
              i18nVariable: activeStage.value === 6 ? 'ai.seeAllStages' : 'ai.nextStage'
            } 
          }
          activeChat.value.chat.chat.push(objectToSend)
          if(obj.stage) activeStage.value = obj.stage
          disabledButton.value = false
        } catch(err) {
          console.error('Error parsing JSON:', err)
        }
      } else {
        const objectToSend = {
            senderId: 1,
            message: responseFlowise.value
        }
        if(activeStage.value === 5) objectToSend.followUp = true
        activeChat.value.chat.chat.push(objectToSend)
        if(activeStage.value !== 5) disabled.value = false
      }
      nextTick(() => { scrollToBottomInChatLog() })
      responseFlowise.value = ''
    }
   
    // Control of messages sent by the user
    const sendUserMessage = async () => {
      if(!inputMessage.value) return

      activeChat.value.chat.chat.push({
        senderId: 2,
        message: inputMessage.value,
      })
      nextTick(() => { scrollToBottomInChatLog() })
      activeMessage.value = inputMessage.value
      setTimeout(() => inputMessage.value = '', 1)
      disabled.value = true
      typingMessage()
      await fetchFlowise(activeMessage.value, sessionId)
      sendChatMessage()
    }

    // Connection with flowise
    const fetchFlowise = (prompt, sessionId) => {
      return new Promise((resolve, reject) => {
        flowise({
            "question": prompt,
            "overrideConfig": {
                "sessionId": sessionId
            }
          })
          .then((res) => {
            responseFlowise.value = res.text
            resolve()
          })
          .catch((error) => {
            console.error("Error in query promise:", error)
            reject()
          })
      })
    }
    
    // Defines class of the stage titles in template
    const getClass = (labelIndex) => {
      if (labelIndex === activeStage.value) {
        return 'process'
      } else if (labelIndex < activeStage.value) {
        return 'finished'
      } else {
        return 'unfinished'
      }
    }
    
    const typingMessage = () => {
      activeChat.value.chat.chat.push({
        senderId: 1,
        message: i18n.t('ai.typing'),
      })
      nextTick(() => { scrollToBottomInChatLog() })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    watch(disabled, (newVal) => {
      if (!newVal) {
        setTimeout(() => {
          inputRef.value.focus()
        }, 100)
      }
    })

    return {
      activeChat,
      inputMessage,
      sendUserMessage,
      clearChatData,
      refChatLogPS,
      disabled,
      activeStage,
      getClass,
      disabledButton,
      chatFlow,
      stageData,
      followUpData,
      skinValue,
      inputRef,
      
      // UI
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss" scoped>

.titleContain {
  margin-bottom: 1rem;
}

.finished {
  font-weight: bold;
  color: #4e8769;
  font-size: 1rem;
}

.unfinished {
  font-weight: bold;
  color: #b8c2cc;
  font-size: 1rem;
}

.process {
  font-weight: bold;
  color: black;
  font-size: 1rem;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>.@/views/habit/flowise.js/ChatLog.vue
