import axios from 'axios';

export default function flowiseConnection() {

    const flowise = async function(question) {
        const url = process.env.VUE_APP_FLOWISE_URL
        
        try {
            const response = await axios.post(url, question, { headers: {"Content-Type": "application/json"}})
            return response.data
    
        } catch (error) {
            console.error("Error querying the API:", error)
            throw error
        }
    }

    return {
        flowise
    }

}