<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + '-' + index"
      class="chat"
      :class="{'chat-left': msgGrp.senderId === formattedChatData.contact.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.senderId === 1 ? formattedChatData.contact.avatar : user.avatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msgData, index) in msgGrp.messages"
          :key="index"
          class="chat-content"
          :style="{ 'background': skinValue === 'dark' ? '#3b455b' : 'white', 'color': skinValue === 'dark' ? 'white' : 'black'}"
        >
          <vue-markdown :source="msgData.msg"></vue-markdown>
          <div v-if="msgData.button" class="mt-1">
            <b-button
              :disabled="msgData.button.type === 'start' && activeStage > -1 ? true : disabledButton"
              variant="blackbtn btn-dark" :class="{'disabled-button': disabledButton}" @click="handleChatFlow(msgData.button.type)">
              {{ $t(msgData.button.i18nVariable) }}
            </b-button>
          </div>

          <!-- Follow Up -->
          <div v-if="msgData.followUp" class="mt-2">
            <b-row>
              <!-- Participants -->
              <b-col md="12">
                <b-form-group :label="$t('label.participants')">
                  <v-select id="v-improvement-assignee"
                    v-model="followUp.participants"
                    label="title"
                    :multiple="true"
                    :options="workersSupervised"
                    :reduce="(worker) => worker.value"
                    :placeholder="$t('placeholder.participants')">
                  </v-select>
                </b-form-group>
              </b-col>
              <!-- Start -->
              <b-col class="mb-1 mb-md-0 mt-3" md="4">
                <b-form-group :label="$t('label.start_date')" :disabled="disabled">
                  <flat-pickr v-model="followUp.startDate" class="form-control" :config="datePickerConfig"></flat-pickr>
                </b-form-group>
              </b-col>
              <!-- End -->
              <!-- <b-col class="mb-1 mb-md-0 mt-3" md="4">
                <b-form-group :label="$t('label.end_date')" :disabled="disabled">
                  <flat-pickr v-model="followUp.endDate" class="form-control" :config="datePickerConfig"></flat-pickr>
                </b-form-group>
              </b-col> -->
              <!-- Duration -->
              <b-col class="mb-1 mb-md-0 mt-3" md="4">
                <b-form-group :label="$t('message.durationMin')" :disabled="disabled">
                  <b-form-spinbutton v-model="followUp.duration" :min="15" :max="120" :step="15"></b-form-spinbutton>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button :disabled="disabled" variant="blackbtn btn-dark" type="text" class="float-right" @click="handlefollowUp(followUp)">OK</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { BAvatar, BRow, BCol, BFormGroup, BFormSpinbutton  } from "bootstrap-vue"
import flatPickr from "vue-flatpickr-component"
import useAssistanceNew from './useAssistanceNew'
import vSelect from "vue-select"
import VueMarkdown  from 'vue-markdown-v2'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    vSelect,
    VueMarkdown,
    BFormSpinbutton
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    disabledButton: {
      type: Boolean,
      required: true,
    },
    activeStage: {
      type: Number,
      required: true
    },
    skinValue: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {

    const disabled = ref(false)
    const user = {
      avatar: require('@/assets/images/avatars/user.png')
    }
    const { datePickerConfig, followUp, workersSupervised } = useAssistanceNew()

    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            button: msg.button,
            followUp: msg.followUp
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              button: msg.button,
              followUp: msg.followUp
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    const handleChatFlow = (value) => {
      emit('chatFlow', value)
    }

    const handlefollowUp = (data) => {
      disabled.value = true
      const endDate = new Date(data.startDate)
      endDate.setMinutes(endDate.getMinutes() + data.duration)
      data.endDate = endDate
      emit('followUpData', data)
    }

    return {
      formattedChatData,
      handleChatFlow,
      datePickerConfig,
      followUp,
      workersSupervised,
      handlefollowUp,
      disabled,
      user
    }
  },
}
</script>

<style scoped>
.disabled-button {
  background-color: #bcbcbc !important;
  border: none;
  margin-right: 1rem
}
</style>
